import { http } from '@/http/axios.js'

export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

// 同步常量
export const SYNC_ZUJI = 'ok_zuji'
export function getDictEnumListAPI(dictCode) {
  return http({
    url: '/admin/sys/dictEnum/list',
    method: 'get',
    params: { dictCode }
  }).then(res => {
    return res.map(item => {
      return {
        name: item.enumText,
        value: item.enumCode
      }
    })
  })
}


// 地图

export const key = '234a9cbb7d973ebdd7f6a9464e42a830'
export const code = '271baeded7d4e6ebec7dd9305ce6003d'
// export const key = '3768813424129a70d14ab4eb3c2f9938'
// export const code = '5ff55006fe7c9254f90859397a308524'
